import { Pipe, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'

@Pipe({
    name: 'safeHtml',
    standalone: true
})
export class SafeHtmlPipe {
    constructor(@Inject(DomSanitizer) private sanitizer: DomSanitizer) { }

    transform(html) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
}